.site-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: #F4F4F3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ddd;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 3em;
  margin-right: 10px;
}

.download-container {
  display: flex;
  align-items: center;
}

/* Buttons, Images, and Layout */
.download-button {
  background-color: #74B9FF;
  color: white;
  padding: 12px 24px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  margin: 0; /* Ensure no additional margin is applied */
}

.download-button:hover {
  background-color: #509ADC; /* Slightly darker shade for hover effect */
}

/* Remove unnecessary mobile styles */
.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .download-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}