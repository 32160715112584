/* Global Font Setup */
body {
    font-family: 'Inter', sans-serif;
    color: #000000;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}
  
h1, h2, h3, h4, h5, h6 {
    color: #000000; /* Black for all headings */
    font-weight: 700; /* Bold headings */
    margin-bottom: 10px;
}
  
p {
    color: #333333; /* Dark gray for body text */
    margin-bottom: 20px;
}
  
.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.blog-post-header {
    padding-bottom: 20px; /* Optional space between header and body */
    border-bottom: 1px solid #ddd; /* Optional styling for separation */
    line-height: 0.6;
}
  
.blog-post-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.001rem; /* Reduce space between title and date */
    line-height: 0.7;

}
  
.blog-post-meta {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem; /* Reduce the space between the date/author and next section */
}
  
.blog-post-description {
    font-size: 1.25rem;
    font-weight: 700;
    color: #181818;
    margin-bottom: 1.5rem; /* Ensure there's some space between description and content */
}
  
.blog-post-image {
    width: 100%;
    height: auto;
    margin-bottom: 0.2rem;
    border-radius: 8px;
}

.blog-post-body {
    padding-top: 20px; /* Add space between the header and body */
}

.blog-post-content {
    font-size: 1.125rem;
    line-height: 1.75;
}
  
@media (min-width: 768px) {
    .blog-post-title {
      font-size: 3rem;
    }
  
    .blog-post-meta {
      font-size: 1.125rem;
    }
  
    .blog-post-description {
      font-size: 1.5rem;
    }
}

